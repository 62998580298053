import services from "../axios/dbManag";

export const checkAffiliazione = (affiliazioneObj) => {
  return services.dbManagV2
    .post("/affiliazioni/societa/check", {
      ...affiliazioneObj,
    })
    .then((res) => {
      let errors = [];
      const checkResults = res.data.results[0];
      const result = checkResults.checkGlobale.check === "OK" ? true : false;
      const status = res.status;
      const objControlloDownloadDocumentoAffiliazione = {};
      if (!result) {
        for (const item of Object.entries(checkResults)) {
          console.log(item);
          objControlloDownloadDocumentoAffiliazione[item[0]] = item[1].check;
          const errorMessage = item[1].errMsg;
          if (errorMessage && item[0] != "checkGlobale") {
            errors.push(errorMessage);
          }
        }
      } else {
        for (const item of Object.entries(checkResults)) {
          // console.log(item);
          objControlloDownloadDocumentoAffiliazione[item[0]] = item[1].check;
        }
      }

      return {
        result,
        errors,
        status,
        objControlloDownloadDocumentoAffiliazione,
      };
    })
    .catch((e) => {
      return e.response;
    });
};

export const checkAffiliazioneSecondaria = (
  id_societa_primaria,
  id_comitato,
  anno,
  email
) => {
  return services.dbManag
    .post("/anagrafiche/societa/secondaria/add", {
      id_societa_primaria,
      id_comitato,
      anno,
      email,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const affiliazioneAdd = (affiliazioneObj) => {
  return services.dbManagV2
    .post("/affiliazioni/societa/add", {
      ...affiliazioneObj,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const deleteAffiliazione = (id) => {
  return services.dbManag
    .post("/affiliazioni/societa/del", {
      id,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const editAffiliazione = (affiliazione) => {
  return services.dbManag
    .post("/affiliazioni/societa/edit", {
      ...affiliazione,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const viewAffiliazione = (id) => {
  return services.dbManag
    .post("/affiliazioni/societa/view", {
      id,
    })
    .then((res) => {
      return { affiliazione: res.data.results[0], status: res.status };
    })
    .catch((e) => {
      return false;
    });
};

export const addPickleballAffiliazione = (
  id_societa,
  campi_pickleball,
  campi_pickleball_proprieta
) => {
  return services.dbManag
    .post("/anagrafiche/societa/campi-pickleball/add", {
      id_societa,
      campi_pickleball,
      campi_pickleball_proprieta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const addTutelaMinoreAffiliazione = (
  id_societa,
  tutore_minori_nome,
  tutore_minori_cognome,
  tutore_minori_cod_fiscale,
  tutore_minori_telefono,
  tutore_minori_mail,
  tutore_minori_comune_nascita
) => {
  return services.dbManag
    .post("/anagrafiche/societa/tutore/add", {
      id_societa,
      tutore_minori_nome,
      tutore_minori_cognome,
      tutore_minori_cod_fiscale,
      tutore_minori_telefono,
      tutore_minori_mail,
      tutore_minori_comune_nascita,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const viewTutelaMinoriAffiliazione = (id_societa) => {
  return services.dbManag
    .post("/anagrafiche/societa/tutela/minori/view", {
      id_societa,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const viewPickleballAffiliazione = (id_societa) => {
  return services.dbManag
    .post("/anagrafiche/societa/campi-pickleball/view", {
      id_societa,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
